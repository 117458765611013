import React from 'react';

export function AsSeenOn() {
  return <svg xmlns="http://www.w3.org/2000/svg"
              width="1.07333in" height="0.896667in"
              viewBox="0 0 322 269" className="as-seen-on">
    <path id="Selection"
          fill="red" stroke="red" strokeWidth="1"
          d="M 143.00,0.42
           C 157.88,-1.39 177.04,0.40 192.00,1.83
             219.27,4.44 235.21,7.01 262.00,12.79
             262.00,12.79 288.00,19.98 288.00,19.98
             294.96,22.14 302.39,24.21 307.96,29.21
             317.88,38.13 319.14,64.93 320.02,78.00
             320.02,78.00 322.00,103.00 322.00,103.00
             322.05,134.73 323.06,166.48 319.28,198.00
             317.81,210.25 317.75,229.68 308.82,238.90
             301.37,246.59 281.51,250.72 271.00,253.71
             238.86,262.84 187.40,269.39 154.00,269.00
             154.00,269.00 133.00,267.00 133.00,267.00
             133.00,267.00 125.00,267.00 125.00,267.00
             100.04,264.45 74.16,260.14 50.00,253.42
             39.93,250.62 21.68,246.51 14.09,239.67
             4.69,231.20 2.61,203.56 2.00,191.00
             2.00,191.00 0.00,164.00 0.00,164.00
             -0.05,132.94 -0.98,101.85 2.72,71.00
             4.17,58.89 4.40,39.17 13.18,30.10
             20.35,22.70 39.81,17.99 50.00,15.37
             68.12,10.71 86.43,6.78 105.00,4.28
             105.00,4.28 143.00,0.42 143.00,0.42 Z" />
    <path id="Recross the paths"
          fill="white" stroke="none" strokeWidth="1"
          d="M 135.00,132.00
           C 135.00,137.88 135.86,145.98 132.30,150.91
             126.47,158.97 107.68,159.46 101.43,151.89
             98.00,147.75 98.01,142.09 98.00,137.00
             98.00,137.00 98.00,113.00 98.00,113.00
             98.06,108.00 98.60,104.08 102.34,100.34
             108.10,94.58 123.70,94.24 129.90,99.42
             136.58,104.99 135.00,114.25 135.00,122.00
             135.00,122.00 119.00,122.00 119.00,122.00
             119.00,116.25 120.61,110.16 115.00,107.00
             113.41,115.89 113.41,137.11 115.00,146.00
             120.49,142.90 119.00,137.65 119.00,132.00
             119.00,132.00 135.00,132.00 135.00,132.00 Z
           M 191.00,96.56
           C 197.76,95.37 206.60,95.02 211.79,100.21
             216.77,105.19 216.99,117.12 217.00,124.00
             217.00,124.00 216.17,140.00 216.17,140.00
             215.40,147.66 214.85,153.58 206.00,156.35
             203.51,157.13 199.66,157.03 197.00,157.00
             194.55,156.97 192.37,156.95 190.00,156.15
             176.90,151.70 180.00,134.02 180.00,123.00
             180.00,111.76 178.02,101.11 191.00,96.56 Z
           M 236.00,146.00
           C 241.80,144.07 241.35,139.32 237.70,135.18
             233.39,130.29 224.45,128.44 221.15,120.00
             219.07,114.70 219.45,104.60 223.42,100.22
             229.09,93.95 247.18,94.33 252.49,101.11
             255.56,105.02 255.00,110.32 255.00,115.00
             255.00,115.00 240.00,115.00 240.00,115.00
             239.84,110.55 240.07,109.29 236.00,107.00
             235.28,117.79 242.30,117.02 249.96,124.18
             254.95,128.85 255.92,132.42 256.00,139.00
             256.05,143.39 256.36,148.24 253.49,151.89
             248.03,158.86 230.35,158.91 224.39,152.66
             219.97,148.03 221.00,140.92 221.00,135.00
             221.00,135.00 236.00,135.00 236.00,135.00
             236.00,135.00 236.00,146.00 236.00,146.00 Z
           M 275.00,146.00
           C 280.80,144.07 280.35,139.32 276.70,135.18
             272.39,130.29 263.45,128.44 260.15,120.00
             258.07,114.70 258.45,104.60 262.42,100.22
             268.09,93.95 286.18,94.33 291.49,101.11
             294.56,105.02 294.00,110.32 294.00,115.00
             294.00,115.00 279.00,115.00 279.00,115.00
             278.84,110.55 279.07,109.29 275.00,107.00
             274.28,117.79 281.30,117.02 288.96,124.18
             293.95,128.85 294.92,132.42 295.00,139.00
             295.05,143.39 295.36,148.24 292.49,151.89
             287.03,158.86 269.35,158.91 263.39,152.66
             258.97,148.03 260.00,140.92 260.00,135.00
             260.00,135.00 275.00,135.00 275.00,135.00
             275.00,135.00 275.00,146.00 275.00,146.00 Z
           M 27.00,97.00
           C 32.91,97.00 51.65,96.14 55.98,98.31
             62.86,101.76 65.31,115.55 61.07,121.78
             59.84,123.57 58.76,123.98 57.00,125.00
             59.00,126.63 60.46,127.64 61.69,130.04
             63.81,134.21 63.00,150.47 63.00,156.00
             63.00,156.00 47.00,156.00 47.00,156.00
             47.00,156.00 47.00,130.00 47.00,130.00
             47.00,130.00 44.00,130.00 44.00,130.00
             44.00,130.00 44.00,156.00 44.00,156.00
             44.00,156.00 27.00,156.00 27.00,156.00
             27.00,156.00 27.00,97.00 27.00,97.00 Z
           M 67.00,97.00
           C 67.00,97.00 94.00,97.00 94.00,97.00
             94.00,97.00 94.00,109.00 94.00,109.00
             94.00,109.00 84.00,109.00 84.00,109.00
             84.00,109.00 84.00,120.00 84.00,120.00
             84.00,120.00 93.00,120.00 93.00,120.00
             93.00,120.00 93.00,131.00 93.00,131.00
             93.00,131.00 84.00,131.00 84.00,131.00
             84.00,131.00 84.00,144.00 84.00,144.00
             84.00,144.00 95.00,144.00 95.00,144.00
             95.00,144.00 95.00,156.00 95.00,156.00
             95.00,156.00 67.00,156.00 67.00,156.00
             67.00,156.00 67.00,97.00 67.00,97.00 Z
           M 140.00,97.00
           C 145.91,97.00 164.65,96.14 168.98,98.31
             175.86,101.76 178.31,115.55 174.07,121.78
             172.84,123.57 171.76,123.98 170.00,125.00
             172.00,126.63 173.46,127.64 174.69,130.04
             176.81,134.21 176.00,150.47 176.00,156.00
             176.00,156.00 160.00,156.00 160.00,156.00
             160.00,156.00 160.00,130.00 160.00,130.00
             160.00,130.00 157.00,130.00 157.00,130.00
             157.00,130.00 157.00,156.00 157.00,156.00
             157.00,156.00 140.00,156.00 140.00,156.00
             140.00,156.00 140.00,97.00 140.00,97.00 Z
           M 200.00,107.00
           C 200.00,107.00 197.00,107.00 197.00,107.00
             195.34,113.81 195.90,130.27 196.00,138.00
             196.06,142.27 195.68,144.44 200.00,146.00
             200.00,146.00 200.00,107.00 200.00,107.00 Z
           M 47.00,108.00
           C 47.00,108.00 44.00,108.00 44.00,108.00
             44.00,108.00 44.00,119.00 44.00,119.00
             44.00,119.00 47.00,119.00 47.00,119.00
             47.00,119.00 47.00,108.00 47.00,108.00 Z
           M 160.00,108.00
           C 160.00,108.00 157.00,108.00 157.00,108.00
             157.00,108.00 157.00,119.00 157.00,119.00
             157.00,119.00 160.00,119.00 160.00,119.00
             160.00,119.00 160.00,108.00 160.00,108.00 Z
           M 293.00,214.00
           C 298.14,211.10 297.85,206.59 293.77,202.67
             288.96,198.04 281.61,196.14 278.45,189.99
             275.78,184.78 276.08,171.81 280.53,167.64
             286.36,162.16 304.35,162.31 309.26,169.19
             311.63,172.50 311.00,178.96 311.00,183.00
             311.00,183.00 296.00,183.00 296.00,183.00
             296.00,183.00 296.00,175.00 296.00,175.00
             290.15,178.31 292.68,181.86 297.02,185.24
             301.84,189.01 307.57,191.26 310.41,197.00
             313.41,202.52 314.18,214.63 310.41,219.73
             304.68,227.17 285.54,226.91 279.88,219.73
             276.45,215.30 277.00,208.27 277.00,203.00
             277.00,203.00 292.00,203.00 292.00,203.00
             292.00,203.00 293.00,214.00 293.00,214.00 Z
           M 7.00,165.00
           C 7.00,165.00 42.00,165.00 42.00,165.00
             42.00,165.00 42.00,177.00 42.00,177.00
             42.00,177.00 33.00,177.00 33.00,177.00
             33.00,177.00 33.00,224.00 33.00,224.00
             33.00,224.00 16.00,224.00 16.00,224.00
             16.00,224.00 16.00,177.00 16.00,177.00
             16.00,177.00 7.00,177.00 7.00,177.00
             7.00,177.00 7.00,165.00 7.00,165.00 Z
           M 45.00,165.00
           C 45.00,165.00 61.00,165.00 61.00,165.00
             61.00,165.00 61.00,187.00 61.00,187.00
             61.00,187.00 65.00,187.00 65.00,187.00
             65.00,187.00 65.00,165.00 65.00,165.00
             65.00,165.00 81.00,165.00 81.00,165.00
             81.00,165.00 81.00,224.00 81.00,224.00
             81.00,224.00 65.00,224.00 65.00,224.00
             65.00,224.00 65.00,199.00 65.00,199.00
             65.00,199.00 61.00,199.00 61.00,199.00
             61.00,199.00 61.00,224.00 61.00,224.00
             61.00,224.00 45.00,224.00 45.00,224.00
             45.00,224.00 45.00,165.00 45.00,165.00 Z
           M 87.00,165.00
           C 87.00,165.00 114.00,165.00 114.00,165.00
             114.00,165.00 114.00,177.00 114.00,177.00
             114.00,177.00 103.00,177.00 103.00,177.00
             103.00,177.00 103.00,188.00 103.00,188.00
             103.00,188.00 113.00,188.00 113.00,188.00
             113.00,188.00 113.00,199.00 113.00,199.00
             113.00,199.00 103.00,199.00 103.00,199.00
             103.00,199.00 103.00,212.00 103.00,212.00
             103.00,212.00 115.00,212.00 115.00,212.00
             115.00,212.00 115.00,224.00 115.00,224.00
             115.00,224.00 87.00,224.00 87.00,224.00
             87.00,224.00 87.00,165.00 87.00,165.00 Z
           M 131.00,165.00
           C 138.39,165.00 156.59,163.14 161.72,168.28
             166.32,172.87 166.67,193.13 161.72,197.49
             158.12,200.67 151.55,200.00 147.00,200.00
             147.00,200.00 147.00,224.00 147.00,224.00
             147.00,224.00 131.00,224.00 131.00,224.00
             131.00,224.00 131.00,165.00 131.00,165.00 Z
           M 171.00,165.00
           C 171.00,165.00 195.00,165.00 195.00,165.00
             195.00,165.00 203.00,224.00 203.00,224.00
             203.00,224.00 187.00,224.00 187.00,224.00
             187.00,224.00 186.00,213.00 186.00,213.00
             186.00,213.00 181.00,213.00 181.00,213.00
             181.00,213.00 181.00,224.00 181.00,224.00
             181.00,224.00 164.00,224.00 164.00,224.00
             164.00,224.00 171.00,165.00 171.00,165.00 Z
           M 198.00,165.00
           C 198.00,165.00 233.00,165.00 233.00,165.00
             233.00,165.00 233.00,177.00 233.00,177.00
             233.00,177.00 224.00,177.00 224.00,177.00
             224.00,177.00 224.00,224.00 224.00,224.00
             224.00,224.00 207.00,224.00 207.00,224.00
             207.00,224.00 207.00,177.00 207.00,177.00
             207.00,177.00 198.00,177.00 198.00,177.00
             198.00,177.00 198.00,165.00 198.00,165.00 Z
           M 236.00,165.00
           C 236.00,165.00 252.00,165.00 252.00,165.00
             252.00,165.00 252.00,187.00 252.00,187.00
             252.00,187.00 256.00,187.00 256.00,187.00
             256.00,187.00 256.00,165.00 256.00,165.00
             256.00,165.00 272.00,165.00 272.00,165.00
             272.00,165.00 272.00,224.00 272.00,224.00
             272.00,224.00 256.00,224.00 256.00,224.00
             256.00,224.00 256.00,199.00 256.00,199.00
             256.00,199.00 252.00,199.00 252.00,199.00
             252.00,199.00 252.00,224.00 252.00,224.00
             252.00,224.00 236.00,224.00 236.00,224.00
             236.00,224.00 236.00,165.00 236.00,165.00 Z
           M 147.00,189.00
           C 152.73,186.99 152.73,178.01 147.00,176.00
             147.00,176.00 147.00,189.00 147.00,189.00 Z
           M 184.00,183.00
           C 184.00,183.00 181.00,203.00 181.00,203.00
             181.00,203.00 185.00,203.00 185.00,203.00
             185.00,203.00 184.00,183.00 184.00,183.00 Z" />
    <path id="As seen on"
          fill="white" stroke="white" strokeWidth="1"
          d="M 54.00,79.00
           C 57.97,80.49 65.45,82.69 66.80,76.41
             67.61,72.61 62.51,69.12 60.00,67.12
             55.18,63.25 52.55,60.44 52.10,54.00
             51.42,44.40 56.88,37.23 67.00,38.11
             69.09,38.29 71.69,38.84 73.01,40.65
             74.32,42.45 74.00,46.77 74.00,49.00
             70.18,47.40 63.32,44.48 62.08,51.04
             61.15,55.95 67.87,59.72 70.91,62.46
             75.47,66.59 77.65,71.86 76.53,78.00
             75.03,86.21 69.24,90.52 61.00,89.90
             58.90,89.75 56.34,89.33 55.02,87.49
             53.70,85.64 54.00,81.28 54.00,79.00 Z
           M 98.00,79.00
           C 102.01,80.50 107.39,82.42 110.59,77.85
             111.17,76.57 111.34,75.45 110.59,74.00
             108.46,67.25 97.39,66.82 97.02,54.00
             96.88,49.23 96.95,45.03 100.53,41.39
             103.84,38.03 113.79,36.04 116.98,40.51
             118.30,42.36 118.00,46.72 118.00,49.00
             114.23,47.43 108.14,44.81 106.74,51.02
             105.76,55.39 112.20,59.71 114.96,62.28
             120.15,67.14 121.94,71.95 120.67,79.00
             119.25,86.89 113.65,90.24 106.00,89.96
             103.66,89.87 100.51,89.57 99.02,87.49
             97.70,85.64 98.00,81.28 98.00,79.00 Z
           M 248.00,38.53
           C 268.22,35.21 271.77,56.89 269.25,72.00
             267.91,79.99 264.79,87.63 256.00,89.61
             246.22,91.81 238.92,86.17 236.05,77.00
             232.13,64.46 233.38,43.22 248.00,38.53 Z
           M 14.00,89.00
           C 14.00,89.00 22.63,53.00 22.63,53.00
             22.63,53.00 26.73,39.99 26.73,39.99
             26.73,39.99 37.00,39.00 37.00,39.00
             37.00,39.00 48.00,89.00 48.00,89.00
             48.00,89.00 38.00,89.00 38.00,89.00
             36.05,79.90 33.44,81.00 25.00,81.00
             25.00,81.00 24.00,89.00 24.00,89.00
             24.00,89.00 14.00,89.00 14.00,89.00 Z
           M 128.00,39.00
           C 128.00,39.00 148.00,39.00 148.00,39.00
             148.00,39.00 148.00,47.00 148.00,47.00
             148.00,47.00 147.00,48.01 147.00,48.01
             144.59,47.42 141.60,46.54 139.31,48.01
             136.48,49.87 136.30,56.11 138.60,58.40
             140.40,60.21 143.64,59.97 146.00,60.00
             146.00,60.00 146.00,68.00 146.00,68.00
             146.00,68.00 137.00,68.00 137.00,68.00
             137.00,79.60 136.11,80.99 148.00,81.00
             148.00,81.00 148.00,89.00 148.00,89.00
             148.00,89.00 128.00,89.00 128.00,89.00
             128.00,89.00 128.00,39.00 128.00,39.00 Z
           M 156.00,39.00
           C 156.00,39.00 176.00,39.00 176.00,39.00
             176.00,39.00 175.00,48.00 175.00,48.00
             175.00,48.00 166.00,47.00 166.00,47.00
             166.00,47.00 166.00,60.00 166.00,60.00
             166.00,60.00 175.00,60.00 175.00,60.00
             175.00,60.00 175.00,68.00 175.00,68.00
             175.00,68.00 166.00,68.00 166.00,68.00
             166.00,68.00 166.00,81.00 166.00,81.00
             166.00,81.00 176.00,81.00 176.00,81.00
             176.00,81.00 176.00,89.00 176.00,89.00
             176.00,89.00 156.00,89.00 156.00,89.00
             156.00,89.00 156.00,39.00 156.00,39.00 Z
           M 184.00,39.00
           C 184.00,39.00 192.50,40.02 192.50,40.02
             192.50,40.02 197.20,49.00 197.20,49.00
             197.20,49.00 206.00,68.00 206.00,68.00
             206.00,68.00 205.00,39.00 205.00,39.00
             205.00,39.00 215.00,39.00 215.00,39.00
             215.00,39.00 215.00,89.00 215.00,89.00
             212.67,89.00 207.53,89.38 205.70,88.01
             204.05,86.77 202.58,82.05 201.72,80.00
             199.20,74.05 196.51,64.28 192.00,60.00
             192.00,60.00 193.00,89.00 193.00,89.00
             193.00,89.00 184.00,89.00 184.00,89.00
             184.00,89.00 184.00,39.00 184.00,39.00 Z
           M 276.00,39.00
           C 276.00,39.00 286.00,39.00 286.00,39.00
             286.00,39.00 298.00,68.00 298.00,68.00
             298.00,68.00 298.00,39.00 298.00,39.00
             298.00,39.00 307.00,39.00 307.00,39.00
             307.00,39.00 307.00,89.00 307.00,89.00
             307.00,89.00 298.41,87.98 298.41,87.98
             298.41,87.98 293.60,79.00 293.60,79.00
             293.60,79.00 285.00,59.00 285.00,59.00
             285.00,59.00 286.00,71.00 286.00,71.00
             286.00,71.00 286.00,89.00 286.00,89.00
             286.00,89.00 276.00,89.00 276.00,89.00
             276.00,89.00 276.00,39.00 276.00,39.00 Z
           M 252.01,46.74
           C 242.77,49.41 243.45,64.36 244.94,72.00
             245.66,75.71 247.46,81.13 252.00,81.46
             256.60,81.79 258.40,76.57 259.18,73.00
             260.83,65.44 261.44,48.97 252.01,46.74 Z
           M 31.00,51.00
           C 28.90,55.56 27.11,68.76 27.00,74.00
             27.00,74.00 35.00,74.00 35.00,74.00
             35.00,74.00 31.00,51.00 31.00,51.00 Z" />
  </svg>

}